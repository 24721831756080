import * as _preact2 from "preact";

var _preact = "default" in _preact2 ? _preact2.default : _preact2;

import * as _core2 from "@uppy/core";

var _core = "default" in _core2 ? _core2.default : _core2;

import _FadeIn from "./FadeIn";
import _TransitionGroup from "./TransitionGroup";
var exports = {};

var _class, _temp;
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions  */

/* eslint-disable jsx-a11y/click-events-have-key-events */


const {
  h
} = _preact;
const {
  UIPlugin
} = _core;
const FadeIn = _FadeIn;
const TransitionGroup = _TransitionGroup;
/**
 * Informer
 * Shows rad message bubbles
 * used like this: `uppy.info('hello world', 'info', 5000)`
 * or for errors: `uppy.info('Error uploading img.jpg', 'error', 5000)`
 *
 */

exports = (_temp = _class = class Informer extends UIPlugin {
  // eslint-disable-next-line global-require
  constructor(uppy, opts) {
    super(uppy, opts);

    this.render = state => {
      return h("div", {
        className: "uppy uppy-Informer"
      }, h(TransitionGroup, null, state.info.map(info => h(FadeIn, {
        key: info.message
      }, h("p", {
        role: "alert"
      }, info.message, " ", info.details && h("span", {
        "aria-label": info.details,
        "data-microtip-position": "top-left",
        "data-microtip-size": "medium",
        role: "tooltip" // eslint-disable-next-line no-alert
        ,
        onClick: () => alert(`${info.message} \n\n ${info.details}`)
      }, "?"))))));
    };

    this.type = "progressindicator";
    this.id = this.opts.id || "Informer";
    this.title = "Informer"; // set default options

    const defaultOptions = {}; // merge default options with the ones set by user

    this.opts = { ...defaultOptions,
      ...opts
    };
  }

  install() {
    const {
      target
    } = this.opts;

    if (target) {
      this.mount(target, this);
    }
  }

}, _class.VERSION = "2.0.5", _temp);
export default exports;